/* eslint-disable no-alert */
<script>
import emailjs from '@emailjs/browser'

export default {
  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    sendEmail() {
      emailjs.sendForm('service_wgn7hps', 'template_e0785gq', this.$refs.form, 'W9ct-FhuQsveU39VZ')
        .then((result) => {
          // eslint-disable-next-line no-alert
          alert('SUCCESS!', result.text)
          this.$refs.form.reset()
        }, (error) => {
          // eslint-disable-next-line no-alert
          alert('FAILED...', error.text)
        })
    }
  }
}
</script>
<template>
  <div
    class="contact-us"
  >
    <div class="line"></div>

    <div class="contact-us-content">
      <div class="contact-us-content-left">
        <p class="contact-us-content-left-text">
          Many happy clients have already been served by CP TRADE FZ LLC, reach out to become one
        </p>
        <div class="contact-us-content-left-tags">
          <div class="contact-us-content-left-tags-tag confidence">
            <p class="contact-us-content-left-tags-tag-text">Confidence</p>
          </div>
          <div class="contact-us-content-left-tags-tag ">
            <p class="contact-us-content-left-tags-tag-text trust">Trust</p>
          </div>
          <div class="contact-us-content-left-tags-tag">
            <p class="contact-us-content-left-tags-tag-text">Admiration</p>
          </div>
          <div class="contact-us-content-left-tags-tag">
            <p class="contact-us-content-left-tags-tag-text">Satisfaction</p>
          </div>
          <div class="contact-us-content-left-tags-tag">
            <p class="contact-us-content-left-tags-tag-text">Joy</p>
          </div>
        </div>
      </div>
      <div class="contact-us-content-right">
        <span class="contact-us-content-right-title">CP TRADE FZ LLC loves all digital
          <img
            class="contact-us-content-right-title-icon"
            src="@/assets/images/svg/heart.svg"
            alt="heart">
        </span>
        <img
          class="contact-us-content-right-image"
          src="@/assets/images/network-mainboard-service-toy-broadcasting.png"
          alt="broadcasting">
        <p
          id="contact-us"
          class="contact-us-content-right-title">
          <span class="contact-us-content-right-title-highlight">Contact us</span> via our contact form to learn more
        </p>
          <form
          class="contact-us-content-right-form"
          ref="form"
          @submit.prevent="sendEmail">
          <div class="contact-us-content-right-form-item">
            <p class="contact-us-content-right-form-item-text">First name</p>
            <input
              class="contact-us-content-right-form-item-input"
              placeholder="Enter your first name"
              name="user_name"
              type="text">
          </div>
          <div class="contact-us-content-right-form-item">
            <p class="contact-us-content-right-form-item-text">Last name</p>
            <input
              class="contact-us-content-right-form-item-input"
              placeholder="Enter your last name"
              name="user_last_name"
              type="text">
          </div>
          <div class="contact-us-content-right-form-item full-width">
            <p class="contact-us-content-right-form-item-text">Email</p>
            <input
              class="contact-us-content-right-form-item-input"
              placeholder="Enter your email"
              name="user_email"
              type="text">
          </div>
          <div class="contact-us-content-right-form-item full-width">
            <p class="contact-us-content-right-form-item-text">Message</p>
            <textarea
              class="contact-us-content-right-form-item-input"
              placeholder="Type your message here"
              rows="5"
              name="message"
              />
          </div>
         <div
          class="contact-us-content-right-form-button full-width"
          @click="this.sendEmail()">
            <p class="contact-us-content-right-form-button-text">submit</p>
            <img
              class="contact-us-content-right-form-button-image"
              src="@/assets/images/svg/angle-arrow.svg"
              alt="arrow">
            </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.contact-us-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: get-vw(1704px);
  margin-left: auto;
  margin-right: auto;
}
.contact-us {
  &-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: get-vw(70px);
    &-left {
      margin-top: get-vw(220px);
      width: get-vw(643px);
      &-text {
        width: get-vw(410px);
        font-weight: 500;
        font-size: get-vw(24px);
        line-height: get-vw(29px);
        color: #000000;
      }
      &-tags {
        width: get-vw(410px);
        margin-top: get-vw(40px);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &-tag {
          display: flex;
          align-items: center;
          justify-content: center;
          height: get-vw(53px);
          border: get-vw(4px) solid #009AF7;
          border-radius: get-vw(16px);
          margin-bottom: get-vw(17px);
          &-text {
            padding: get-vw(14px) get-vw(31px);
            font-weight: 400;
            font-size: get-vw(24px);
          }
        }
      }

    }

    &-right {
      width: get-vw(1061px);
      display: flex;
      flex-direction: column;
      &-title {
        font-weight: 700;
        font-size: get-vw(72px);
        line-height: 110%;
        &-highlight {
          color: #009AF6;
        }
        &-icon {
          width: get-(58px);
          height: get-(51.6px);
        }
      }
      &-image {
        margin-top: get-vw(64px);
        margin-bottom: get-vw(80px);
        width: get-vw(1061px);
        height: get-vw(489px);
      }
      &-form {
        display: grid;
        grid-template: auto / repeat(2, 1fr);
        grid-gap: get-vw(24px);
        margin-top: get-vw(55px);
        &-item {
          display: flex;
          flex-direction: column;
          &-text {
            font-weight: 500;
            font-size: get-vw(24px);
            line-height: get-vw(29px);
            color: #000000;
          }
          &-input {
            margin-top: get-vw(16px);
            border-radius: get-vw(8px);
            padding: get-vw(19px);
            font-size: get-vw(24px);
            resize: vertical;
          }
        }
        &-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: get-vw(65px);
          background: #009AF7;
          border: get-vw(4px) solid #009AF7;
          border-radius: get-vw(8px);
          transition: all 0.3s;
          cursor: pointer;
          margin-top: get-vw(40px);

          &:hover {
            background: #0289dd;
          }
          &-text {
            font-weight: 500;
            font-size: get-vw(24px);
            line-height: get-vw(29px);
            color: #FFFFFF;
          }
          &-image {
            width: get-vw(13px);
            height: get-vw(14px);
            margin-left: get-vw(14px);
          }
        }
      }
    }
  }
}

.trust {
  padding: get-vw(13px) get-vw(65px) !important;
}

.full-width {
  grid-column: 1 / 3;
}
</style>
